<template>
  <div>
    <v-tabs horizontal>
      <v-tab>
        <v-icon left>
          {{ icons.mdiCogOutline }}
        </v-icon>
        {{ $t('ProfilePage.Profile') }}
      </v-tab>
      <v-tab>
        <v-icon left>
          {{ icons.mdiLockOutline }}
        </v-icon>
        {{ $t('ProfilePage.ChangePassword') }}
      </v-tab>

      <v-tab-item>
        <v-card flat>
          <v-overlay
            :absolute="true"
            :value="loading"
            opacity="0.5"
            color="#c0c0c0"
          >
            <v-progress-circular
              indeterminate
              size="64"
            ></v-progress-circular>
          </v-overlay>
          <v-card-text>
            <v-form
              ref="form"
              v-model="valid"
              lazy-validation
            >
              <v-row>
                <v-col
                  cols="8"
                  xl="8"
                  md="8"
                  sm="8"
                >
                  <v-row>
                    <v-col
                      cols="12"
                      xl="12"
                      md="12"
                      sm="12"
                    >
                      <v-text-field
                        v-model="name"
                        :rules="nameRules"
                        :label="$t('ProfilePage.UserName')"
                        required
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      cols="12"
                      xl="12"
                      md="12"
                      sm="12"
                    >
                      <v-text-field
                        v-model="email"
                        :label="$t('ProfilePage.Email')"
                        readonly
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      cols="12"
                      xl="12"
                      md="12"
                      sm="12"
                    >
                      <v-text-field
                        :label="$t('ProfilePage.Role')"
                        :value="$t(`ProfilePage.Roles.${role}`)"
                        readonly
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-btn
                        :disabled="!valid"
                        color="primary"
                        class="mr-4"
                        @click="saveUser()"
                      >
                        {{ $t('ProfilePage.Save') }}
                      </v-btn>

                      <v-btn
                        color="primary"
                        class="mr-4"
                        outlined
                        @click="revertChanges()"
                      >
                        {{ $t('ProfilePage.Cancel') }}
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col
                  cols="4"
                  xl="4"
                  md="4"
                  sm="4"
                >
                  <v-file-input
                    v-model="avatar"
                    accept="image/png, image/jpeg, image/jpg"
                    :label="$t('ProfilePage.UploadAvatar')"
                    @change="Preview_image"
                    @click:clear="revertImage"
                  >
                    >
                  </v-file-input>
                  <div class="d-flex justify-center mb-4 caption">
                    {{ $t('ProfilePage.AvatarRequerments') }}
                  </div>
                  <div
                    class="d-flex justify-center"
                  >
                    <v-avatar
                      class="v-avatar-light-bg primary--text"
                      color="primary"
                      size="200"
                    >
                      <v-img
                        v-if="url || newAvavtar"
                        :src="url || newAvavtar"
                      ></v-img>
                      <v-icon
                        v-else
                        color="primary"
                        size="100"
                      >
                        {{ icons.mdiAccountOutline }}
                      </v-icon>
                    </v-avatar>
                  </div>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <v-form
              ref="formPassword"
              v-model="validPasswordForm"
              lazy-validation
            >
              <v-row>
                <v-col
                  cols="12"
                  xl="12"
                  md="12"
                  sm="12"
                >
                  <v-text-field
                    v-model="oldPassword"
                    :rules="passwordRules"
                    :append-icon="showOldPassword ? icons.mdiEye : icons.mdiEyeOff"
                    :label="$t('ProfilePage.OldPassword')"
                    :type="showOldPassword ? 'text' : 'password'"
                    :error-messages="errorMessages"
                    required
                    @change="checkPassword(oldPassword)"
                    @click:append="showOldPassword = !showOldPassword"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  xl="12"
                  md="12"
                  sm="12"
                >
                  <v-text-field
                    v-model="newPassword"
                    :rules="passwordRules"
                    :append-icon="showNewPassword ? icons.mdiEye : icons.mdiEyeOff"
                    :label="$t('ProfilePage.NewPassword')"
                    :type="showNewPassword ? 'text' : 'password'"
                    required
                    @click:append="showNewPassword = !showNewPassword"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  xl="12"
                  md="12"
                  sm="12"
                >
                  <v-text-field
                    v-model="confirmPassword"
                    :rules="[
                      v => !!v || this.$t('UserPage.Validation.PasswordRequired'),
                      v => v === newPassword|| this.$t('UserPage.Validation.PasswordNotMatch'),
                      v => (v || '').length >= 6 || this.$t('UserPage.Validation.PasswordLength'),
                    ]"
                    :append-icon="showConfirmPassword ? icons.mdiEye : icons.mdiEyeOff"
                    :label="$t('ProfilePage.ConfirmPassword')"
                    :type="showConfirmPassword ? 'text' : 'password'"
                    required
                    @click:append="showConfirmPassword = !showConfirmPassword"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-btn
                    :disabled="!validPasswordForm"
                    color="primary"
                    class="mr-4"
                    @click="updatePassword()"
                  >
                    {{ $t('UserPage.UpdatePassword') }}
                  </v-btn>

                  <v-btn
                    color="primary"
                    class="mr-4"
                    outlined
                    @click="revertChanges()"
                  >
                    {{ $t('UserPage.Cancel') }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs>
    <v-snackbar
      v-model="showMessage"
      :timeout="timeout"
      :color="color"
    >
      {{ messageText }}
    </v-snackbar>
  </div>
</template>
<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiEye,
  mdiEyeOff,
  mdiPencil,
  mdiCheck,
  mdiCloseCircleOutline,
  mdiCogOutline,
  mdiLockOutline,
  mdiAccountOutline,
} from '@mdi/js'

export default {
  data() {
    return {
      icons: {
        mdiEye,
        mdiEyeOff,
        mdiPencil,
        mdiCheck,
        mdiCloseCircleOutline,
        mdiCogOutline,
        mdiLockOutline,
        mdiAccountOutline,
      },
      dataFromDb: [],
      avatar: {},
      url: '',
      loading: false,
      showMessage: false,
      timeout: 5000,
      color: 'success',
      messageText: '',
      dialog: false,
      valid: true,
      validPasswordForm: true,
      name: '',
      newAvavtar: null,
      avatarChanged: false,
      privileges: [],
      privilegesTree: [],
      map: {},
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
      errorMessages: '',
      selectedPrivileges: [],
      userData: { name: '' },
      userStorageData: JSON.parse(localStorage.getItem('userData')),
      showOldPassword: false,
      showNewPassword: false,
      showConfirmPassword: false,
      nameRules: [v => !!v || this.$t('UserPage.Validation.NameRequired')],
      email: '',
      emailRules: [
        v => !!v || this.$t('UserPage.Validation.EmailRequired'),
        v => /.+@.+\..+/.test(v) || this.$t('UserPage.Validation.EmailIncorrect'),
      ],
      role: { name: 'ROOT' },
      roles: [],
      agency: '',
      passwordRules: [
        v => !!v || this.$t('UserPage.Validation.PasswordRequired'),
        v => (v || '').length >= 6 || this.$t('UserPage.Validation.PasswordLength'),
      ],
      password: '',
      status: 'active',
      statuses: [
        { nameEn: 'Active', nameRu: 'Активный', value: 'active' },
        { nameEn: 'Inactive', nameRu: 'Не активный', value: 'inactive' },
      ],
    }
  },
  computed: {
    headers() {
      return [
        { text: 'ID', value: 'id' },
        { text: this.$t('UserPage.UserName'), value: 'name' },
        { text: this.$t('UserPage.Email'), value: 'email' },
        { text: this.$t('UserPage.Role'), value: 'role' },
        { text: this.$t('UserPage.Status'), value: 'status' },
        { text: this.$t('UserPage.Actions'), value: 'actions' },
      ]
    },
  },
  mounted() {
    this.getData()
  },
  methods: {
    Preview_image() {
      if (this.avatar) {
        const reader = new FileReader()
        reader.readAsDataURL(this.avatar)
        reader.onloadend = () => {
          this.newAvavtar = reader.result
          this.url = null
          this.avatarChanged = true
        }
      }
    },
    revertImage() {
      this.url = this.userData.avatarBase64
      this.avatar.name = ''
      this.newAvavtar = null
    },
    revertChanges() {
      this.getData()
      this.newPassword = ''
      this.oldPassword = ''
      this.confirmPassword = ''
      this.revertImage()
    },
    checkPassword(password) {
      const params = {
        params: {
          userId: this.$getUserId(),
          password,
        },
      }
      this.$http.get(`${this.$apiBaseURL}/user/check-password`, params).then(response => {
        if (!response.data) {
          this.errorMessages = this.$t('ProfilePage.Validation.IncoreectOldPassword')
        } else {
          this.errorMessages = ''
        }
      })
    },
    checkPasswordPromise(password) {
      return new Promise((resolve, reject) => {
        const params = {
          params: {
            userId: this.$getUserId(),
            password,
          },
        }
        this.$http.get(`${this.$apiBaseURL}/user/check-password`, params).then(response => {
          if (!response.data) {
            this.errorMessages = this.$t('ProfilePage.Validation.IncoreectOldPassword')
            reject(response.data)
          } else {
            this.errorMessages = ''
            resolve(response.data)
          }
          console.log(this.passResult)
        })
      })
    },
    updatePassword() {
      const valid = this.$refs.formPassword.validate()
      // eslint-disable-next-line no-useless-return
      if (!valid) return

      const params = {
        id: this.$getUserId(),
        name: this.name,
        isDeleted: this.userData.isDeleted,
        status: this.userData.status,
        role: this.role,
        agency: this.userData.agency,
        createdAt: this.userData.createdAt,
        updatedAt: this.userData.updatedAt,
        deletedAt: this.userData.deletedAt,
        email: this.email,
        privileges: this.privileges,
        password: this.newPassword,
      }
      this.checkPasswordPromise(this.oldPassword).then(() => {
        this.$http.put(`${this.$apiBaseURL}/profile/password-change`, params).then(() => {
          // тут сохраняем привязку.
          this.messageText = this.$t('UserPage.Validation.PasswordChangesSuccess')
          this.showMessage = true
        })
      })
    },
    saveUser() {
      const valid = this.$refs.form.validate()
      // eslint-disable-next-line no-useless-return
      if (!valid) return

      const params = {
        id: this.$getUserId(),
        name: this.name,
        password: this.password,
        isDeleted: this.userData.isDeleted,
        status: this.userData.status,
        role: this.role,
        agency: this.userData.agency,
        createdAt: this.userData.createdAt,
        updatedAt: this.userData.updatedAt,
        deletedAt: this.userData.deletedAt,
        email: this.email,
        privileges: this.privileges,
      }
      if (this.avatarChanged) {
        params.avatar = this.avatar.name
        params.avatarBase64 = this.newAvavtar
      }

      this.$http
        .put(`${this.$apiBaseURL}/profile`, params)
        .then(() => {
          // тут сохраняем привязку.
          this.messageText = this.$t('ProfilePage.Validation.UserUpdatedSuccess')
          this.showMessage = true
          this.avatarChanged = false
          this.userStorageData.name = this.name
          this.userStorageData.avatarBase64 = this.newAvavtar || this.url

          // this.userData.avatar = this.url
          localStorage.setItem('userData', JSON.stringify(this.userStorageData))
        })
        .then(() => {
          this.$eventHub.$emit('updateUserData')
        })
    },
    getData() {
      this.loading = true

      this.$http.get(`${this.$apiBaseURL}/user/details`, { params: { userId: this.$getUserId() } }).then(response => {
        this.userData = response.data !== null ? response.data : []
        if (this.userData) {
          this.avatar.name = this.userData.avatar
          this.role = this.userData.role
          this.privileges = this.userData.privileges
          this.name = this.userData.name
          this.email = this.userData.email
          this.status = this.userData.status
          this.url = this.userData.avatarBase64
        }
        this.loading = false
      })
    },
  },
}
</script>
